import React from 'react';

import { Check as CheckIcon } from '@styled-icons/boxicons-regular/Check';
import { TrashAlt as TrashAltIcon } from '@styled-icons/boxicons-solid/TrashAlt';
import { Warning as WarningIcon } from '@styled-icons/entypo/Warning';
import { Close as CloseIcon } from '@styled-icons/evaicons-solid/Close';
import InfoIcon from 'mdi-react/InfoCircleOutlineIcon';

import {
  Box,
  Icon,
  Td,
  Text,
  Input,
  Flex,
  Tooltip,
  Tr,
  HStack,
  Button,
  IconButton,
} from '@chakra-ui/react';

import { Row, FlattenedComponent } from './types';

export type Input = {
  price: number;
  newQuantity: number;
  recursive_quantity: number;
  input_price: {
    base: {
      description: string;
    };
    version: {
      description: string;
    };
    input: {
      code: string;
      description: string;
      version: {
        year?: number;
        month?: number;
        description: string;
        base: {
          description: string;
        };
      };
      unit_measure: {
        description: string;
      };
    };
  };
};

interface RowProps {
  row: Row & { original: FlattenedComponent & Input };
  activeTab: 'list' | 'selected';
  isSiengeBudget: boolean;
  selectedComponents: FlattenedComponent[];
  handleSelectComponent: (component: FlattenedComponent) => void;
  handleDeleteComponent: (component: FlattenedComponent) => void;
}

const InputRow: React.FC<RowProps> = ({
  row,
  selectedComponents,
  activeTab,
  isSiengeBudget,
  handleSelectComponent,
  handleDeleteComponent,
}) => {
  return (
    <Tr {...row.getRowProps()}>
      <Td>
        <Box
          display="flex"
          flexDir="column"
          ml="1"
          justifyContent="center"
          alignItems="center"
          title="Insumo"
          fontWeight="bold"
        >
          {row.original.location} <Icon w={5} h={5} as={InfoIcon} />
        </Box>
      </Td>

      <Td title="Base / Referência">
        <Text textAlign="center">
          {row.original.input_price.base?.description}{' '}
        </Text>

        <Text
          textAlign="center"
          whiteSpace="pre-wrap"
          maxWidth={{ base: '100%', md: '650px' }}
        >
          {row.original.input_price.version?.description ||
            row.original.input_price.input?.version?.description}
        </Text>
      </Td>

      <Td>{row.original.input_price.input.code}</Td>
      <Td>
        <Text
          py={1}
          whiteSpace="pre-wrap"
          maxWidth={{ base: '100%', md: '650px' }}
        >
          {row.original.input_price.input.description}
        </Text>
      </Td>

      <Td isNumeric>
        {Intl.NumberFormat('pt-BR', {
          maximumFractionDigits: 4,
        }).format(row.original.quantity)}
      </Td>

      <Td isNumeric>
        <Flex gap={2} alignItems="center" justifyContent="flex-end">
          <Text>
            {Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(row.original.social_law_price)}
          </Text>

          {row.original.outdated_price && !isSiengeBudget && (
            <Tooltip
              ml={1}
              hasArrow
              label="Insumo com preço desatualizado em relação a sua versão original."
            >
              <Icon as={WarningIcon} className="icon" />
            </Tooltip>
          )}
        </Flex>
      </Td>

      <Td isNumeric>
        {Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }).format(row.original.price)}
      </Td>

      <Td isNumeric>
        {Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }).format(row.original.total)}
      </Td>

      <Td>
        {activeTab === 'list' ? (
          <HStack spacing={2}>
            <Button
              colorScheme="green"
              leftIcon={<Icon as={CheckIcon} />}
              onClick={() => handleSelectComponent(row.original)}
              disabled={selectedComponents.some(
                (c) => c.id === row.original.id,
              )}
            >
              {selectedComponents.some((c) => c.id === row.original.id)
                ? 'Selecionado'
                : 'Selecionar'}
            </Button>

            {selectedComponents.some((c) => c.id === row.original.id) && (
              <IconButton
                aria-label="Remover composição"
                colorScheme="red"
                icon={<Icon as={CloseIcon} />}
                onClick={() => handleDeleteComponent(row.original)}
              />
            )}
          </HStack>
        ) : (
          <Button
            tabIndex={-1}
            colorScheme="red"
            leftIcon={<Icon as={TrashAltIcon} />}
            onClick={() => handleDeleteComponent(row.original)}
          >
            Remover
          </Button>
        )}
      </Td>
    </Tr>
  );
};

export default InputRow;
