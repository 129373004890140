import React, { useCallback } from 'react';
import {
  TableBodyPropGetter,
  TableBodyProps,
  Row,
  ColumnInstance,
} from 'react-table';

import { Tbody, Tr } from '@chakra-ui/react';

import Loader from 'components/Loader';
import { Td } from 'components/Table';

import CompositionRow from './CompositionRow';
import InputRow from './InputRow';
import { FlattenedComponent, Stage } from './types';

type RowType = Stage['type'];

interface OutdatedComponentsBodyProps {
  getTableBodyProps: (
    propGetter?: TableBodyPropGetter<FlattenedComponent> | undefined,
  ) => TableBodyProps;
  loading: boolean;
  data: FlattenedComponent[];
  rows: Row<FlattenedComponent>[];

  visibleColumns: ColumnInstance<FlattenedComponent>[];
  prepareRow: (row: Row<FlattenedComponent>) => void;

  activeTab: 'list' | 'selected';
  isSiengeBudget: boolean;

  selectedComponents: FlattenedComponent[];
  handleSelectComponent: (component: FlattenedComponent) => void;
  handleDeleteComponent: (component: FlattenedComponent) => void;
}

const OutdatedComponentsBody: React.FC<OutdatedComponentsBodyProps> = ({
  getTableBodyProps,
  loading,
  data,
  rows,
  prepareRow,
  activeTab,
  isSiengeBudget,
  selectedComponents,
  handleSelectComponent,
  handleDeleteComponent,
}) => {
  const getRow = useCallback(
    (type: RowType, row: Row<FlattenedComponent>) => {
      if (type === 'composition_original') {
        return (
          <CompositionRow
            activeTab={activeTab}
            selectedComponents={selectedComponents}
            isSiengeBudget={isSiengeBudget}
            handleSelectComponent={handleSelectComponent}
            handleDeleteComponent={handleDeleteComponent}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            row={row as any}
            {...row.getRowProps()}
          />
        );
      }

      if (type === 'input_original') {
        return (
          <InputRow
            activeTab={activeTab}
            selectedComponents={selectedComponents}
            isSiengeBudget={isSiengeBudget}
            handleSelectComponent={handleSelectComponent}
            handleDeleteComponent={handleDeleteComponent}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            row={row as any}
            {...row.getRowProps()}
          />
        );
      }

      return null;
    },
    [
      isSiengeBudget,
      activeTab,
      selectedComponents,
      handleSelectComponent,
      handleDeleteComponent,
    ],
  );

  return (
    <Tbody {...getTableBodyProps()}>
      {(!loading || data.length >= 0) &&
        rows.map((row) => {
          prepareRow(row);
          return getRow(row.original.type, row);
        })}

      {loading && (
        <Tr>
          <Td columnKey={1} colSpan={1000} paddingY={4}>
            <Loader />
          </Td>
        </Tr>
      )}

      {!loading && data.length === 0 && (
        <Tr>
          <Td columnKey={1} colSpan={1000} paddingY={4}>
            {activeTab === 'list'
              ? 'Nenhum componente desatualizado encontrado'
              : 'Nenhum componente selecionado'}
          </Td>
        </Tr>
      )}
    </Tbody>
  );
};

export default OutdatedComponentsBody;
