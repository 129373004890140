import React, { Suspense, useCallback, useEffect, useState } from 'react';

import { ArrowRight as ArrowRightIcon } from '@styled-icons/bootstrap/ArrowRight';
import { Check as CheckIcon } from '@styled-icons/boxicons-regular/Check';
import { api } from 'services/api';
import { isAxiosError } from 'services/axios';
import { toast } from 'shared/toast';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Button,
  Icon,
  Text,
  Box,
  useMediaQuery,
  Progress,
} from '@chakra-ui/react';

import Loader from 'components/Loader';
import { ModalRootProps } from 'components/Modal/Root';

import OutdatedComponentsTable from './OutdatedComponentsTable';
import { FlattenedComponent } from './types';

interface ModalOutdatedComponentSelect extends ModalRootProps {
  data: {
    budgetId: number;
    isSiengeBudget?: boolean;
  };
}

type SelectedComponent = FlattenedComponent;

const modalMargin = 120;
const modalMobileFix = 100;

const ModalBudgetOutdatedComponentSelect: React.FC<ModalOutdatedComponentSelect> =
  ({
    data: { budgetId, isSiengeBudget },
    handleClose,
    onConfirm,
    ...restProps
  }) => {
    const [activeTab, setActiveTab] = useState(0);
    const [isMobile] = useMediaQuery('(max-width: 728px)');

    const [saving, setSaving] = useState(false);
    const [fakeProgress, setFakeProgress] = useState(0);

    const [components, setComponents] = useState<FlattenedComponent[]>([]);
    const [selectedComponents, setSelectedComponents] = useState<
      SelectedComponent[]
    >([]);

    const [loading, setLoading] = useState(false);

    const handleTabsChange = (index: number): void => {
      setActiveTab(index);
    };

    const getData = useCallback(async () => {
      setLoading(true);
      setComponents([]);

      try {
        const { data: response } = await api.get(
          `/budget/${budgetId}/component`,
          {
            params: {
              'filter[outdated_price]': 1,
              sort: 'position',
            },
          },
        );

        const componentsData = response.data;
        setComponents(componentsData);
      } catch (err) {
        toast({
          status: 'error',
          description:
            err.response?.data?.message ||
            'Houve um erro ao buscar composições',
        });

        setComponents([]);
      } finally {
        setLoading(false);
      }
    }, [budgetId]);

    useEffect(() => {
      getData();
    }, [getData]);

    const handleSelectComponent = (item: FlattenedComponent): void => {
      setSelectedComponents((prepared) => [
        ...prepared,
        {
          ...item,
        },
      ]);
    };

    const handleDeleteComponent = (item: FlattenedComponent): void => {
      setSelectedComponents((selected) =>
        selected.filter((c) => c.id !== item.id),
      );
    };

    const toggleSelectAllComponents = (): void => {
      if (selectedComponents.length === components.length) {
        setSelectedComponents([]);
      } else {
        setSelectedComponents(components);
      }
    };

    // const prepareComponents = useCallback((): ComponentData => {
    //   return {
    //     // parent_stage_id: subStageId,
    //     // components: selected.map((item) => ({
    //     //   type: 'composition_original',
    //     //   composition_id: Number(item.id),
    //     //   quantity: item.quantity || 0,
    //     //   budget_base_id:
    //     //     bases.find(
    //     //       (b) => b.version.base.id === item._source.version.base.id,
    //     //     )?.id || 0,
    //     // })),
    //   } as ComponentData;
    // }, []);

    const handleSubmitComponents = useCallback(async () => {
      setLoading(true);
      setSaving(true);
      setFakeProgress(0);

      try {
        // await api.post(`budget/${budgetId}/component`, {
        //   ...prepareComponents(selectedComponents),
        // });

        toast({
          description: 'Composições salvas com sucesso!',
          status: 'success',
        });

        setFakeProgress(99);

        setTimeout(() => {
          if (onConfirm) onConfirm();
          handleClose();
        }, 1000);
      } catch (err) {
        if (isAxiosError(err)) {
          toast({
            description:
              err.response?.data?.message ||
              'Houve um erro ao salvar as composições selecionadas.',
            status: 'error',
          });
        }
      } finally {
        setSaving(false);
        setLoading(false);
      }
    }, [handleClose, onConfirm]);

    useEffect(() => {
      if (saving) {
        const interval = setInterval(() => {
          setFakeProgress((oldProgress) => {
            if (oldProgress === 90) {
              return oldProgress;
            }

            if (oldProgress === 99.9) {
              // wait 1 second to close modal
              setTimeout(() => {
                setFakeProgress(100);
              }, 1000);

              clearInterval(interval);
              return 0;
            }

            return Math.min(oldProgress + 10, 100);
          });
        }, 3000);

        return () => clearInterval(interval);
      }

      return undefined;
    }, [saving]);

    return (
      <Modal {...restProps} scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent
          sx={{
            width: `calc(100% - ${
              isMobile ? modalMargin - modalMobileFix : modalMargin
            }px)`,
            maxWidth: `calc(100% - ${
              isMobile ? modalMargin - modalMobileFix : modalMargin
            }px)`,
            height: `calc(100% - ${
              isMobile ? modalMargin - modalMobileFix : modalMargin
            }px)`,
            maxHeight: `calc(100% - ${
              isMobile ? modalMargin - modalMobileFix : modalMargin
            }px)`,
          }}
        >
          <ModalHeader>Selecionar componentes para atualização</ModalHeader>

          <ModalCloseButton />

          {!isMobile && (
            <Button
              position="absolute"
              right="50px"
              top="10px"
              colorScheme="green"
              rightIcon={
                activeTab === 0 ? <Icon as={ArrowRightIcon} /> : undefined
              }
              leftIcon={activeTab === 1 ? <Icon as={CheckIcon} /> : undefined}
              isLoading={loading}
              isDisabled={
                (activeTab === 1 && selectedComponents.length === 0) || loading
              }
              onClick={() => {
                if (activeTab === 0) {
                  setActiveTab(1);
                } else {
                  handleSubmitComponents();
                }
              }}
            >
              {activeTab === 0 ? 'Avançar' : 'Salvar'}
            </Button>
          )}

          <ModalBody>
            {fakeProgress > 0 && fakeProgress <= 100 && (
              <>
                <Text fontWeight="bold" fontSize="md">
                  Salvando composições...
                </Text>

                <Progress
                  hasStripe
                  isAnimated
                  value={fakeProgress === 99 ? 100 : fakeProgress}
                  my={4}
                  borderRadius="3px"
                />
              </>
            )}

            <Tabs
              variant="line"
              colorScheme="red"
              index={activeTab}
              onChange={handleTabsChange}
            >
              <TabList>
                <Tab _selected={{ fontWeight: 'bold' }}>
                  {isSiengeBudget
                    ? 'Lista de componentes'
                    : 'Componentes desatualizados'}
                </Tab>
                <Tab _selected={{ fontWeight: 'bold' }}>
                  Selecionados{' '}
                  {selectedComponents.length > 0 && (
                    <Box
                      width={4}
                      height={4}
                      marginX={2}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      backgroundColor="red.600"
                      fontSize="10px"
                      fontWeight="bold"
                      color="white"
                      borderRadius="50%"
                    >
                      {selectedComponents.length}
                    </Box>
                  )}
                </Tab>
              </TabList>

              <Suspense fallback={<Loader />}>
                <TabPanels>
                  <TabPanel p={0} pt={4}>
                    <OutdatedComponentsTable
                      data={components}
                      loading={loading}
                      activeTab="list"
                      isSiengeBudget={isSiengeBudget || false}
                      selectedComponents={selectedComponents}
                      handleSelectComponent={handleSelectComponent}
                      handleDeleteComponent={handleDeleteComponent}
                      toggleSelectAllComponents={toggleSelectAllComponents}
                    />
                  </TabPanel>

                  <TabPanel p={0} pt={4}>
                    <OutdatedComponentsTable
                      data={selectedComponents}
                      loading={loading}
                      activeTab="selected"
                      isSiengeBudget={isSiengeBudget || false}
                      selectedComponents={selectedComponents}
                      handleSelectComponent={handleSelectComponent}
                      handleDeleteComponent={handleDeleteComponent}
                      toggleSelectAllComponents={toggleSelectAllComponents}
                    />

                    {isMobile && (
                      <Button
                        w="100%"
                        type="submit"
                        colorScheme="green"
                        isLoading={loading}
                        isDisabled={
                          selectedComponents.length === 0 || loading || saving
                        }
                        leftIcon={<Icon as={CheckIcon} />}
                        onClick={handleSubmitComponents}
                        mt="4"
                      >
                        Salvar
                      </Button>
                    )}
                  </TabPanel>
                </TabPanels>
              </Suspense>
            </Tabs>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  };

export default ModalBudgetOutdatedComponentSelect;
