import React, { useMemo, useCallback } from 'react';
import { useTable } from 'react-table';

import {
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Text,
  Box,
  Checkbox,
  Flex,
} from '@chakra-ui/react';

import OutdatedComponentsTableBody from './OutdatedComponentsTableBody';
import { Row, FlattenedComponent } from './types';

interface OutdatedComponentsTableProps {
  data: FlattenedComponent[];
  loading: boolean;
  activeTab: 'list' | 'selected';
  isSiengeBudget: boolean;
  selectedComponents: FlattenedComponent[];
  handleSelectComponent: (component: FlattenedComponent) => void;
  handleDeleteComponent: (component: FlattenedComponent) => void;
  toggleSelectAllComponents: () => void;
}

const OutdatedComponentsTable: React.FC<OutdatedComponentsTableProps> = ({
  data,
  loading,
  activeTab,
  isSiengeBudget,
  selectedComponents,
  handleSelectComponent,
  handleDeleteComponent,
  toggleSelectAllComponents,
}) => {
  const columns = useMemo(
    () => [
      {
        Header: 'Item',
        key: 'item',
        width: '5%',
        isCentered: true,
      },
      {
        Header: 'Base',
        width: '10%',
        key: 'base',
        isCentered: true,
      },
      {
        Header: 'Código',
        width: '5%',
        key: 'code',
      },

      {
        Header: 'Descrição',
        width: '50%',
        Cell: ({ row }: { row: Row }) => {
          return (
            <Box display="flex" gap={1}>
              <Text fontWeight="bold">{`${row.original.location} -`}</Text>
              {row.original.title}
            </Box>
          );
        },
      },
      {
        Header: 'Qtde',
        width: '1%',
        colSpan: 10,
        isNumeric: true,
        key: 'quantity',
        Cell: ({ row }: { row: Row }) => {
          const value = row.original.quantity;

          return Intl.NumberFormat('pt-BR', {
            maximumFractionDigits: 7,
          }).format(value || 0);
        },
      },
      {
        Header: 'Unit.',
        width: '1%',
        isNumeric: true,
        key: 'unitary_value',
      },
      {
        Header: 'Com BDI',
        width: '1%',
        key: 'with_bdi',
        isNumeric: true,
      },
      {
        id: 'total',
        Header: 'Total',
        width: '1%',
        isNumeric: true,
        Cell: ({ row }: { row: Row }) => {
          const value = row.original.total;
          return Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(value || 0);
        },
      },
      {
        Header: () => (
          <Flex
            gap={1}
            alignItems="center"
            justifyContent="center"
            textAlign="center"
          >
            {activeTab === 'list' && (
              <Checkbox
                mr={1}
                onChange={toggleSelectAllComponents}
                isChecked={
                  data.length > 0 && selectedComponents.length === data.length
                }
                title="Selecionar todos"
              />
            )}
            <Text fontWeight="bold" textAlign="center">
              Ações
            </Text>
          </Flex>
        ),
        id: 'actions',
        width: '5%',
        Cell: () => {
          return <></>;
        },
      },
    ],
    [data, activeTab, selectedComponents, toggleSelectAllComponents],
  );

  const getRowId = useCallback((row) => {
    return row.id;
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
  } = useTable({
    columns,
    data,
    getRowId,
  });

  return (
    <TableContainer mt={4}>
      <Table {...getTableProps()} variant="simple">
        <Thead>
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Th
                  {...column.getHeaderProps()}
                  width={column.width}
                  // @ts-expect-error isNumeric does not exists on lib type
                  isNumeric={column.isNumeric}
                  // @ts-expect-error isCentered does not exists on lib type
                  textAlign={column.isCentered ? 'center' : undefined}
                >
                  {column.render('Header')}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>

        <OutdatedComponentsTableBody
          getTableBodyProps={getTableBodyProps}
          data={data}
          rows={rows}
          loading={loading}
          prepareRow={prepareRow}
          activeTab={activeTab}
          isSiengeBudget={isSiengeBudget}
          visibleColumns={visibleColumns}
          selectedComponents={selectedComponents}
          handleSelectComponent={handleSelectComponent}
          handleDeleteComponent={handleDeleteComponent}
        />
      </Table>
    </TableContainer>
  );
};

export default OutdatedComponentsTable;
